import { RequestType } from "src/interfaces";
import { Observable } from "rxjs";

export default function Request(url: string, requestOptions: RequestType): Observable<any> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");

  console.log(requestOptions, "request");
  return Observable.create((observer: any) => {
    requestOptions.credentials = "same-origin";
    requestOptions.headers = headers;
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          observer.next(data);
          observer.complete();
        }
        observer.error(data);
      })
      .catch((error) => {
        observer.error(error);
      });
  });
}
